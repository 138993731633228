


















































































































import { VueSelecto } from "vue-selecto";
import { ScheduleRepository } from "@/modules/schedule/schedule.repository";
import Vue from "vue";

export default Vue.extend({
  components: { VueSelecto },

  props: {},

  data() {
    return {
      doctorsList: [] as any[] | null,
      schedules: {} as any,
      week: 0,
      weekType: "",
      weekInfo: "",
      timeIntervals: [] as any[],
      currentDoctor: { id: 0, name: "" },
    };
  },

  computed: {
    profileDoctorId() {
      if (this.$store.state.auth.profile) {
        return this.$store.state.auth.profile.id;
      }
      return null;
    },
    isLargeScreen() {
      return window.innerWidth > 1024;
    },
  },

  async created() {
    if (this.$store.state.auth.profile) {
      await this.loadData(true);
    }
  },

  watch: {
    profileDoctorId(val) {
      if (val) {
        this.loadData(true);
      }
    },
  },

  methods: {
    async loadData(getDoctors = false, newDoctor = false) {
      if (getDoctors) {
        this.currentDoctor = { id: this.$store.state.auth.profile.id };
        const { data } = await this.$registry.get(ScheduleRepository).getDoctors();
        this.doctorsList = data;
      }

      if (newDoctor) {
        this.currentDoctor = newDoctor;
      }

      const response = await this.$registry.get(ScheduleRepository).getCalendarByDoctor({
        week: this.week,
        weekType: this.weekType,
        doctorId: this.currentDoctor.id,
      });

      if (!response) {
        this.$dialog.notify.error("ошибка получения данных календаря");
        return;
      }

      this.schedules = response.schedules;
      this.week = response.week;
      this.weekInfo = response.week_info;
      this.timeIntervals = response.time_intervals;
    },

    previousWeek() {
      this.weekType = "prev-week";
      this.loadData();
    },

    nextWeek() {
      this.weekType = "next-week";
      this.loadData();
    },

    /**
     * Открывает время на запись
     * @param interval
     */
    async openTime(interval, silent = false) {
      const { success, message } = await this.$registry
        .get(ScheduleRepository)
        .toggleTimeByDoctor(interval.ts, this.currentDoctor.id);
      if (success) {
        interval.schedule = {};
        if (!silent) {
          this.$dialog.notify.success(`Время ${interval.time} открыто для записи`);
        }
        return;
      }

      this.$dialog.notify.error(message);
    },

    /**
     * Закрывает время на запись
     * @param interval
     */
    async closeTime(interval, silent = false) {
      const { success, message } = await this.$registry
        .get(ScheduleRepository)
        .toggleTimeByDoctor(interval.ts, this.currentDoctor.id);
      if (success) {
        interval.schedule = null;
        if (!silent) {
          this.$dialog.notify.info(`Время ${interval.time} закрыто для записи`);
        }
        return;
      }

      this.$dialog.notify.error(message);
    },

    onSelect(e) {
      console.log(`e =>`, e);
      e.added.forEach((el) => {
        el.classList.add("selecting");
      });
      e.removed.forEach((el) => {
        el.classList.remove("selecting");
      });
    },

    onSelected(e) {
      console.log(`selecto =>`);
      const requestInfo = [];
      e.selected.forEach((el) => {
        el.classList.remove("selecting");
        if (el.dataset.time && el.dataset.date) {
          requestInfo.push(this.schedules[el.dataset.date].items[el.dataset.time]);
        }
      });
      this.toggleTime(requestInfo);
    },

    toggleTime(intervals) {
      intervals.forEach((interval) => {
        if (!interval.disable && interval.schedule === null) {
          this.openTime(interval, true);
        }
        if (!interval.disable && interval.schedule !== null && !interval.schedule.reservation) {
          this.closeTime(interval, true);
        }
      });
      if (intervals.length) {
        this.$dialog.notify.info(`Расписание изменено`);
      }
    },
  },
});
